export const xorMaskShapeMap = {
  none: "",
  circle: "M 123.2 13.4 137.9 18.8 151.5 26.6 163.5 36.7 173.5 48.7 181.3 62.2 186.7 76.9 189.4 92.4 189.4 108 186.7 123.4 181.3 138.1 173.5 151.7 163.5 163.7 151.5 173.7 137.9 181.6 123.2 186.9 107.8 189.6 92.1 189.6 76.7 186.9 62 181.6 48.4 173.7 36.4 163.7 26.4 151.7 18.6 138.1 13.2 123.4 10.5 108 10.5 92.4 13.2 76.9 18.6 62.2 26.4 48.7 36.4 36.7 48.4 26.6 62 18.8 76.7 13.4 92.1 10.7 107.8 10.7 Z",
  squareWithRoundedCorners: "M 176.9 15.1 178.8 15.7 180.5 16.6 182 17.9 183.3 19.4 184.2 21.1 184.8 23 185 25 185 175 184.8 176.9 184.2 178.8 183.3 180.5 182 182 180.5 183.3 178.8 184.2 176.9 184.8 175 185 25 185 23 184.8 21.1 184.2 19.4 183.3 17.9 182 16.6 180.5 15.7 178.8 15.1 176.9 15 175 15 25 15.1 23 15.7 21.1 16.6 19.4 17.9 17.9 19.4 16.6 21.1 15.7 23 15.1 25 15 175 15 Z",
  square: "M 185 185 15 185 15 15 185 15 Z",
  diamond: "M 190 100 100 190 10 100 100 10 Z"
}

export type XorMaskType = keyof typeof xorMaskShapeMap;
export const xorMaskTypes = Object.keys(xorMaskShapeMap) as XorMaskType[];
